document.addEventListener("turbolinks:load", function () {
  // click outside of dropdown to close
  document.addEventListener("click", function (e) {
    if (
      !e.target.classList.contains("navigation-list-item") &&
      !e.target.classList.contains("navigation-toggle")
    ) {
      document
        .querySelectorAll(".navigation-toggle")
        .forEach(function (toggle) {
          if (toggle.classList.contains("open")) {
            toggle.classList.remove("open");
          }
        });
    }
  });

  document
    .querySelectorAll(".navigation-toggle").forEach(function(toggle) {
      toggle.addEventListener("click", function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        if (e.target.classList.contains("open")) {
          e.target.classList.remove("open");
        } else {
          e.target.classList.add("open");
        }
      });
    });

  document
    .querySelectorAll(".navigation-with-children")
    .forEach(function (toggle) {
      var nav_switch = toggle.querySelector(".navigation-toggle");
      var interval;

      toggle.addEventListener("mouseenter", function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        if (!nav_switch.classList.contains("open")) {
          nav_switch.classList.add("open");
        }

        if (interval) {
          clearInterval(interval);
        }
      });

      toggle.addEventListener("mouseleave", function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        interval = setInterval(function () {
          if (nav_switch.classList.contains("open")) {
            nav_switch.classList.remove("open");
          }
        }, 500);
      });
    });
});
