// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, or any plugin's
// vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//
//= require_tree .


window.Rails = require("@rails/ujs")
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import * as ActionCable from '@rails/actioncable'
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
//import RailsNestedForm from '@stimulus-components/rails-nested-form/dist/stimulus-rails-nested-form.umd'
import StimulusReflex from 'stimulus_reflex'
import consumer from '../../channels/consumer'
import '@stimulus_reflex/polyfills'
const application = Application.start()
//application.register('nested-form', RailsNestedForm)
const context = require.context("./controllers", true, /\.js$/)

application.load(definitionsFromContext(context))
application.consumer = consumer
StimulusReflex.initialize(application, { debug: false, isolate: true })
Rails.start()


  StimulusReflex.debug = true


import './scripts/filter_select';
import "core-js/stable";

// Polyfills
// import "regenerator-runtime/runtime";
// import 'whatwg-fetch'
// import 'focus-visible/dist/focus-visible.min.js'
// import "core-js/modules/es7.promise.finally";
// Object-fit & object-position support for older browsers
// import objectFitImages from 'object-fit-images';
// objectFitImages('img', {watchMQ: true});
// Smooth scroll to anchor links
// const SmoothScroll = require('smooth-scroll')
// const scroll = new SmoothScroll('a.scrollto');
// ------------------
// Gather all js files from components folders

import 'element-closest-polyfill'
import CustomEvent from 'custom-event-polyfill';



  import '/reading_schools/app/components/accordion/accordion_component.js';

  import '/reading_schools/app/components/video/video_component.js';

  import '/reading_schools/app/components/core/header/navigation/navigation.js';

  import '/reading_schools/app/components/cookies_bar/cookies-bar.js';

