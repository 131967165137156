import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  delegateParams(e) {
    const form = new FormData(e.currentTarget);
    const urlSearchParams = new URLSearchParams(form).toString();
    const csvDownloadLink = document.querySelector(".button--download");
    csvDownloadLink.setAttribute(
      "href",
      `/accreditation_stats.csv?${urlSearchParams}`
    );
  }

  handleResult() {
    const [_data, _status, xhr] = event.detail;
    const json = JSON.parse(xhr.response);
    const outputElement = document.querySelector("[data-map-locations-value]");
    outputElement.setAttribute("data-map-locations-value", xhr.response);
  }

  clearForm(e) {
    e.preventDefault();
    const inputs = document.getElementsByClassName(
      "multi-select-input-checkbox"
    );
    inputs.forEach((input) => {
      input.checked = false;
    });

    const csvDownloadLink = document.querySelector(".button--download");
    csvDownloadLink.setAttribute("href", "/accreditation_stats.csv");

    Rails.fire(this.element, "submit");
  }
}
