import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  scroll(e) {
    if (typeof document.body.scrollIntoView === "function") {
      e.preventDefault();

      const element = document.querySelector(
        e.currentTarget.getAttribute("href")
      );

      try {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } catch (error) {
        element.scrollIntoView(true);
      }
    }
  }
}
