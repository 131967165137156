import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["validatorOutput", "addOutput"];

  submit(event) {
    Rails.fire(this.element, "submit");
  }

  submitted() {
    const [_data, status, xhr] = event.detail;
    const json = JSON.parse(xhr.response);

    this.validatorOutputTarget.classList.toggle(
      "valid",
      json["action_plan_ready?"]
    );
  }

  added(e) {
    const [_data, _status, xhr] = event.detail;
    const html = xhr.response;

    this.addOutputTarget.innerHTML = html;
    e.currentTarget.remove();
    this.element.dispatchEvent(new CustomEvent("change"));
  }
}
