import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle", "remainder"];
  static values = {
    max: Number,
    hidden: Boolean,
  };

  connect() {
    if (this.hiddenValue) {
      this.tags = this.element.innerText.split(", ");
      if (this.tags.length > this.maxValue) {
        this._initializeRevealable();
      }
    }
  }

  show(e) {
    e.preventDefault();
    this.toggleTarget.innerHTML = ", ";
    this.remainderTarget.style.display = "inline";
  }

  _initializeRevealable() {
    const initialTags = this.tags.slice(0, this.maxValue);
    const remainingTags = this.tags.slice(this.maxValue);
    const output = `${this._renderInitialTags(
      initialTags.join(", ")
    )}${this._renderToggleLink(
      remainingTags.length
    )}${this._renderRemainingTags(remainingTags.join(", "))}`;

    this.element.innerHTML = output;
  }

  _renderToggleLink(count) {
    return `<span data-tag-reveal-target="toggle">\
      and <a href="#" class="link" data-action="tag-reveal#show">${count} more</a>\
    </span>`;
  }

  _renderInitialTags(tags) {
    return `<span>${tags}</span>`;
  }

  _renderRemainingTags(tags) {
    return `<span data-tag-reveal-target="remainder" style="display: none;">${tags}</span>`;
  }
}
