import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  focusTarget(e) {
    e.preventDefault();
    const skipToElement = document.querySelector(
      e.currentTarget.getAttribute("href")
    );
    skipToElement.setAttribute("tabindex", "-1");
    skipToElement.focus();

    if (!this.linkedElementListener) {
      skipToElement.addEventListener("blur", (_e) => {
        skipToElement.removeAttribute("tabindex");
      });

      this.linkedElementListener = true;
    }
  }
}
