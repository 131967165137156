import { Controller } from "@hotwired/stimulus";
import { SelectInputUtils } from "./utils/select_input_utils";
import axios from "axios";

export default class extends Controller {
  static classes = ["hidden"];
  static targets = [
    "form",
    "schoolTypeSelect",
    "schoolSelect",
    "continue",
    "waitlist",
    "waitlistNameOutput",
    "hiddenSchoolIdInput",
    "schoolAddress",
  ];
  static values = {
    localAuthority: String,
    schoolType: String,
    schoolId: String,
    schools: Array,
    school: Object,
    schoolAddress: String,
  };

  initialize() {
    this.formTarget.reset();
  }

  select(e) {
    const stage = e.currentTarget.dataset.actionStage;
    this.valueSetters(stage)(e.currentTarget.value);
  }

  valueSetters(stage) {
    return {
      localAuthority: (value) => {
        this.localAuthorityValue = value;
      },
      schoolType: (value) => {
        this.schoolTypeValue = value;
      },
      schoolId: (value) => {
        this.schoolIdValue = value;
      },
      schoolAddress: (value) => {
        this.schoolAddressValue = value;
      }
    }[stage];
  }

  localAuthorityValueChanged(value) {
    this.fetchSchools();

    SelectInputUtils.reset(this.schoolTypeSelectTarget);
    SelectInputUtils.disable(this.schoolTypeSelectTarget, value === "");
  }

  schoolTypeValueChanged(value) {
    this.fetchSchools();

    SelectInputUtils.reset(this.schoolSelectTarget);
    SelectInputUtils.disable(this.schoolSelectTarget, value === "");
  }

  schoolIdValueChanged() {
    this.continueTarget.classList.add(this.hiddenClass);
    this.fetchSchoolEligibility();
  }

  schoolsValueChanged() {
    this.populateSchoolSelect();
  }

  schoolValueChanged(value) {
    this.waitlistNameOutputTarget.innerText = value.name;
    this.hiddenSchoolIdInputTarget.value = value.id;
    this.schoolAddressValue = value.display_address;
    this.waitlistOrContinue(value.eligible);
  }

  schoolAddressValueChanged(value) {
    if(value != "") {
      this.schoolAddressTarget.innerHTML = `<p class='p-small'><strong>Address:</strong> ${value}</p>`;
    } else {
      this.schoolAddressTarget.innerHTML = "";
    }
  }

  fetchSchools() {
    if (this.localAuthorityValue == "" || this.schoolTypeValue == "") {
      return;
    }

    const filterParams = new URLSearchParams({
      "filter[local_authority]": this.localAuthorityValue,
      "filter[school_type]": this.schoolTypeValue,
    });

    axios
      .get(`/schools.json?${filterParams}`)
      .then((response) => (this.schoolsValue = response.data))
      .catch((err) => console.error(err));
  }

  fetchSchoolEligibility() {
    if (this.schoolIdValue == "") {
      this.schoolAddressValue = ''
      return;
    }

    axios
      .get(`/schools/${this.schoolIdValue}.json`)
      .then((response) => (this.schoolValue = response.data))
      .catch((err) => console.error(err));
  }

  populateSchoolSelect() {
    SelectInputUtils.clear(this.schoolSelectTarget, true);
    this.schoolsValue.forEach((school) => {
      SelectInputUtils.addOption(this.schoolSelectTarget, [
        school.id,
        school.name,
      ]);
    });
  }

  waitlistOrContinue(eligible) {
    if (eligible == null) {
      return;
    }
    this.waitlistTarget.classList.toggle(this.hiddenClass, eligible);
    this.continueTarget.classList.toggle(this.hiddenClass, !eligible);
  }
}
