import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper';
import { Navigation, Keyboard, A11y } from 'swiper/modules/index.mjs';

export default class extends Controller {
  static targets = ['container'];


  connect() {
    const carousel = new Swiper(this.containerTarget, {
      slidesPerView: this.notLessThanOne(3),
      modules: [Navigation, Keyboard, A11y],
      spaceBetween: 32,
      loop: this.containerTarget.dataset.loop ? this.containerTarget.dataset.loop === 'true' : false,
      loopAdditionalSlides: this.containerTarget.dataset.loop === 'true' ? 3 : 0,
      stopOnLastSlide: this.containerTarget.dataset.loop === 'true' ? false : true,

      // grabCursor: true,
      navigation: {
        nextEl: '.carousel__header-controls-item--next',
        prevEl: '.carousel__header-controls-item--prev',
      },
      a11y: true,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },

      breakpoints: {
        768: {
          slidesPerView: this.notLessThanOne(1),
        },
        1024: {
          slidesPerView: this.notLessThanOne(1),
        },
      }
    });
  }

  notLessThanOne(divider) {
    const value = Math.round(this.containerTarget.dataset.slidesPerView / divider);
    return value >= 1 ? value : 1;
  }

}