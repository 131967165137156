document.addEventListener('turbolinks:load', function() {
  const accordions = document.querySelectorAll('.accordion-row-header');

  if (accordions) {
    accordions.forEach(function(toggle) {
      toggle.addEventListener('click', toggleAccordion)
    })
  }

  function toggleAccordion(e) {
    const $this_panel = e.target.closest('.accordion-row')
    const $this_button = $this_panel.querySelector('.accordion-toggle');

    if ($this_panel.classList.contains('open')) {
      $this_panel.classList.remove('open');
      $this_button.setAttribute('aria-expanded', 'false');
    } else {
      $this_panel.classList.add('open');
      $this_button.setAttribute('aria-expanded', 'true');
    }
  }
})
