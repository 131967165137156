import { Controller } from "@hotwired/stimulus";
import querystring from "querystring";
import axios from "axios";
import AddSearchClient from "addsearch-js-client";
import * as u from "JS/utils";

export default class extends Controller {
  static targets = ["form", "input", "submit", "reset", "search", "slugsInput"];

  connect() {
    for (const i in this.inputTargets) {
      const input = this.inputTargets[i];
      switch (input.type) {
        case "select":
          input.selectedIndex = 0;
          break;
        default:
          input.value = "";
          break;
      }
    }

    this.filter();
    this.client = new AddSearchClient("7d3ae6de6f2c13e5fd9dedf9d8a4115f");
    this.client.setPaging(1, 300, "relevance", "desc");
    this.client.setFuzzyMatch(false);
    // 0x for domain, 1x for first path level, 2x for second etc.
    // We filter the domain so SBT can use a single addSearch account for multiple sites
    // https://www.addsearch.com/docs/indexing/restrict-search-area
    this.client.setCategoryFilters("0xwww.readingschools.scot,1xresources");

    this.submitTarget.classList.add("visually-hidden");
    this.submitTarget.setAttribute("aria-hidden", "true");
    this.submitTarget.tabIndex = -1;
  }

  searchSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.search(this.searchTarget.value);
    return false;
  }

  search(term) {
    if (term === "") {
      this.sendSlugData("");
      return;
    }

    const slugs = [];

    this.client.search(term, (res) => {
      res.hits.forEach((result) => {
        let slug = result.url;
        if (slug.charAt(slug.length - 1) === "/") {
          slug = slug.slice(0, -1); // sometimes the urls end with '/'
        }
        slug = slug.split("/").pop();
        slugs.push(slug);
      });
      slugs.push("error-slug"); //this ensures the empty results renders 'No results' prompt
      this.sendSlugData(slugs);
    });
  }

  sendSlugData(slugs) {
    this.slugsInputTarget.value = slugs;
    this.filter();
  }

  getResults(filters, page) {
    const params = Object.assign(filters, { page: page });
    const output = document.querySelector(this.data.get("output"));
    const paginator = document.querySelector(this.data.get("paginator"));

    axios
      .get(this.data.get("url"), {
        params,
        responseType: "text",
        paramsSerializer: (params) => querystring.stringify(params),
      })
      .then(function (response) {
        if (page > 1) {
          paginator.remove();
          const currentItems = output.innerHTML;
          output.innerHTML = currentItems + response.data;
        } else {
          output.innerHTML = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getOptions() {
    const params = {};
    this.inputTargets.forEach((input) => {
      Object.assign(params, { [input.name]: input.value });
    });
    this.toggleResetButton(
      this.inputTargets.some((input) => {
        return this._isInputDirty(input);
      })
    );
    return params;
  }

  toggleResetButton(selected) {
    this.resetTarget.classList.toggle("visually-hidden", !selected);
    if (selected) {
      this.resetTarget.tabIndex = 0;
      this.resetTarget.setAttribute("aria-hidden", "false");
    } else {
      this.resetTarget.tabIndex = -1;
      this.resetTarget.setAttribute("aria-hidden", "true");
    }
  }

  filter() {
    this.getResults(this.getOptions(), 1);
  }

  loadMore(e) {
    e.preventDefault();
    this.getResults(this.getOptions(), e.currentTarget.dataset.page);
  }

  _isInputDirty(el) {
    let input = el;
    if (input !== null) {
      switch (input.type) {
        case "select":
          return input.selectedIndex > 0;
        default:
          return input.value !== "";
      }
    }
  }
}
