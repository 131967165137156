export const MapPins = {
  in_progress: `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="30" viewBox="0 0 38 46"><circle id="f823ec84-9c91-484e-9d13-16246bba0add" fill="#fff" cx="19" cy="19" r="7"/><path d="M19 2A17 17 0 0 0 2 19c0 9.36 7.13 15.75 14 21.94 1 .9 2 1.79 3 2.69 1-.9 2-1.79 3-2.69C28.87 34.75 36 28.36 36 19A17 17 0 0 0 19 2Zm0 24a7 7 0 1 1 7-7 7 7 0 0 1-7 7Z" fill="#343432"/><path fill="#fff" d="M19 0A19 19 0 0 0 0 19c0 10.25 7.47 17 14.7 23.43 1.22 1.1 2.44 2.19 3.62 3.3A1 1 0 0 0 19 46a1 1 0 0 0 .69-.27c1.17-1.11 2.39-2.2 3.61-3.3C30.53 36 38 29.25 38 19A19 19 0 0 0 19 0Zm3 40.94c-1 .9-2 1.79-3 2.69-1-.9-2-1.79-3-2.69C9.13 34.75 2 28.36 2 19a17 17 0 0 1 34 0c0 9.36-7.13 15.75-14 21.94Z"/><circle fill="#fff" cx="19" cy="19" r="7"/></svg>`,
  early_years: `<svg xmlns="http://www.w3.org/2000/svg" width="38" height="46"><circle id="fa707dc3-c2c4-4488-b8c4-d9e77fcab768" cx="19" cy="19" r="7" fill="#fff"/><path fill="#e02582" d="M19 2A17 17 0 0 0 2 19c0 9.35 7.13 15.75 14 21.94 1 .9 2 1.79 3 2.69 1-.9 2-1.79 3-2.69C28.87 34.75 36 28.35 36 19A17 17 0 0 0 19 2Zm0 24a7 7 0 1 1 7-7 7 7 0 0 1-7 7Z"/><path d="M19 0A19 19 0 0 0 0 19c0 10.25 7.47 17 14.7 23.43 1.22 1.1 2.44 2.19 3.62 3.3A1 1 0 0 0 19 46a1 1 0 0 0 .69-.27c1.17-1.11 2.39-2.2 3.62-3.3C30.53 36 38 29.25 38 19A19 19 0 0 0 19 0Zm3 40.94c-1 .9-2 1.79-3 2.69-1-.9-2-1.79-3-2.69C9.13 34.75 2 28.35 2 19a17 17 0 0 1 34 0c0 9.35-7.13 15.75-14 21.94Z" fill="#fff"/><circle cx="19" cy="19" r="7" fill="#fff"/></svg>`,
  core: `<svg xmlns="http://www.w3.org/2000/svg" width="38" height="46"><circle id="fa707dc3-c2c4-4488-b8c4-d9e77fcab768" cx="19" cy="19" r="7" fill="#fff"/><path fill="#00a7d6" d="M19 2A17 17 0 0 0 2 19c0 9.35 7.13 15.75 14 21.94 1 .9 2 1.79 3 2.69 1-.9 2-1.79 3-2.69C28.87 34.75 36 28.35 36 19A17 17 0 0 0 19 2Zm0 24a7 7 0 1 1 7-7 7 7 0 0 1-7 7Z"/><path d="M19 0A19 19 0 0 0 0 19c0 10.25 7.47 17 14.7 23.43 1.22 1.1 2.44 2.19 3.62 3.3A1 1 0 0 0 19 46a1 1 0 0 0 .69-.27c1.17-1.11 2.39-2.2 3.62-3.3C30.53 36 38 29.25 38 19A19 19 0 0 0 19 0Zm3 40.94c-1 .9-2 1.79-3 2.69-1-.9-2-1.79-3-2.69C9.13 34.75 2 28.35 2 19a17 17 0 0 1 34 0c0 9.35-7.13 15.75-14 21.94Z" fill="#fff"/><circle cx="19" cy="19" r="7" fill="#fff"/></svg>`,
  silver: `<svg xmlns="http://www.w3.org/2000/svg" width="38" height="46"><defs><radialGradient id="b5dc68e3-0878-4dd6-a64e-7dc732bc8c7b" cx="-902.01" cy="598.2" r=".92" gradientTransform="matrix(46.76 0 0 -46.77 42216.13 27976.7)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#dedfdc"/><stop offset=".68" stop-color="#bfc2c5"/><stop offset="1" stop-color="#9ba1a8"/></radialGradient></defs><path fill="url(#b5dc68e3-0878-4dd6-a64e-7dc732bc8c7b)" d="M19 2A17 17 0 0 0 2 19c0 9.35 7.13 15.75 14 21.94 1 .9 2 1.79 3 2.69 1-.9 2-1.79 3-2.69C28.87 34.75 36 28.35 36 19A17 17 0 0 0 19 2Z"/><path d="M19 0A19 19 0 0 0 0 19c0 10.25 7.47 17 14.7 23.43 1.22 1.1 2.44 2.19 3.62 3.3A1 1 0 0 0 19 46a1 1 0 0 0 .69-.27c1.17-1.11 2.39-2.2 3.62-3.3C30.53 36 38 29.25 38 19A19 19 0 0 0 19 0Zm3 40.94c-1 .9-2 1.79-3 2.69-1-.9-2-1.79-3-2.69C9.13 34.75 2 28.35 2 19a17 17 0 0 1 34 0c0 9.35-7.13 15.75-14 21.94Z" fill="#fff"/><circle id="e7467b85-9691-46dd-9482-ac474526faec" cx="19" cy="19" r="7" fill="#fff"/></svg>`,
  gold: `<svg xmlns="http://www.w3.org/2000/svg" width="38" height="46"><defs><radialGradient id="b5dfffde-663d-4b0f-bb35-efb82d29a153" cx="-902.01" cy="530.79" r=".92" gradientTransform="matrix(46.76 0 0 -46.77 42216.13 24823.93)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#f7e6a4"/><stop offset=".68" stop-color="#eace76"/><stop offset="1" stop-color="#d5a940"/></radialGradient></defs><circle id="b6223281-75cf-4695-abcc-df419fc7e6a8" cx="19" cy="19" r="7" fill="#fff"/><path fill="url(#b5dfffde-663d-4b0f-bb35-efb82d29a153)" d="M19 2A17 17 0 0 0 2 19c0 9.36 7.13 15.75 14 21.94 1 .9 2 1.79 3 2.69 1-.9 2-1.79 3-2.69C28.87 34.75 36 28.35 36 19A17 17 0 0 0 19 2Zm0 24a7 7 0 1 1 7-7 7 7 0 0 1-7 7Z"/><path d="M19 0A19 19 0 0 0 0 19c0 10.25 7.47 17 14.7 23.43 1.22 1.1 2.44 2.19 3.62 3.3A1 1 0 0 0 19 46a1 1 0 0 0 .69-.27c1.17-1.11 2.39-2.2 3.62-3.3C30.53 36 38 29.25 38 19A19 19 0 0 0 19 0Zm3 40.94c-1 .9-2 1.79-3 2.69-1-.9-2-1.79-3-2.69C9.13 34.75 2 28.36 2 19a17 17 0 0 1 34 0c0 9.35-7.13 15.75-14 21.94Z" fill="#fff"/><circle cx="19" cy="19" r="7" fill="#fff"/></svg>`,
};

export const ClusterPin = (count) => {
  return `<svg xmlns="http://www.w3.org/2000/svg" width="38" height="46" viewBox="0 0 38 46"><circle id="f823ec84-9c91-484e-9d13-16246bba0add" fill="#343432" cx="19" cy="19" r="7"/><path d="M19 2A17 17 0 0 0 2 19c0 9.36 7.13 15.75 14 21.94 1 .9 2 1.79 3 2.69 1-.9 2-1.79 3-2.69C28.87 34.75 36 28.36 36 19A17 17 0 0 0 19 2Zm0 24a7 7 0 1 1 7-7 7 7 0 0 1-7 7Z" fill="#343432"/><path fill="#fff" d="M19 0A19 19 0 0 0 0 19c0 10.25 7.47 17 14.7 23.43 1.22 1.1 2.44 2.19 3.62 3.3A1 1 0 0 0 19 46a1 1 0 0 0 .69-.27c1.17-1.11 2.39-2.2 3.61-3.3C30.53 36 38 29.25 38 19A19 19 0 0 0 19 0Zm3 40.94c-1 .9-2 1.79-3 2.69-1-.9-2-1.79-3-2.69C9.13 34.75 2 28.36 2 19a17 17 0 0 1 34 0c0 9.36-7.13 15.75-14 21.94Z"/><circle fill="#343432" cx="19" cy="19" r="7"/><text x="50%" y="50%" text-anchor="middle" fill="#fff" stroke="#fff" stroke-width="" dy=".2em">${count}</text></svg>`;
};
