export const SelectInputUtils = {
  clear(select, preserveEmptyOption = false) {
    const clearIndex = preserveEmptyOption ? 1 : 0
    while (select.options.length > clearIndex) {
      select.remove(clearIndex)
    }
  },

  addOption(select, [value, text]) {
    select.add(new Option(text, value))
  },

  disable(select, disabled = true) {
    if (disabled) {
      select.closest(".form-input-group").classList.add("disabled")
      select.removeAttribute("disabled")
    } else {
      select.closest(".form-input-group").classList.remove("disabled")
      select.removeAttribute("disabled")
    }
  },

  reset(select) {
    select.selectedIndex = 0
    select.dispatchEvent(new CustomEvent('change'));
  }
}
