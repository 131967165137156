import { Controller } from "@hotwired/stimulus";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Loader } from "@googlemaps/js-api-loader";
import { MapStyles } from "./utils/map_styles.js";
import { MapPins, ClusterPin } from "./utils/map_pins.js";

export default class extends Controller {
  static targets = ["map", "modal", "modalContent"];
  static values = {
    initialized: Boolean,
    locations: Array,
  };

  connect() {
    this.initializeGoogleMaps();
  }

  initializeGoogleMaps() {
    const loader = new Loader({
      apiKey: "AIzaSyABVAB30LTx6VQWfCxuO9aNBUzr5xQDJQc",
      version: "weekly",
    });

    loader
      .load()
      .then((google) => {
        this.google = google;
        return this.google;
      })
      .then(() => {
        this.map();
        this.markers();
        this.markerClusterer();
        this.buildLocations();
        this.initializedValue = true;
      })
      .catch((e) => {
        console.log("error initializing google platform");
      });
  }

  map() {
    this._map = new this.google.maps.Map(this.mapTarget, {
      styles: MapStyles,
      center: new this.google.maps.LatLng(56.322242, -3.652932),
      zoom: 8,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
    });

    return this._map;
  }

  markers() {
    if (this._markers == undefined) {
      this._markers = [];
    }
    return this._markers;
  }

  markerClusterer(markers = []) {
    const google = this.google;
    const map = this._map;
    const renderer = {
      render({ count, position }) {
        return new google.maps.Marker({
          position,
          icon: `data:image/svg+xml;charset=UTF-8;base64,${btoa(
            ClusterPin(count)
          )}`,
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        });
      },
    };

    if (this._markerCluster == undefined) {
      this._markerCluster = new MarkerClusterer({ map, renderer });
    } else {
      this._markerCluster.clearMarkers();
      this._markerCluster = new MarkerClusterer({ markers, map, renderer });
    }

    return this._markerCluster;
  }

  locationsValueChanged(state) {
    if (this.initializedValue != true) {
      return;
    }
    this.buildLocations();
  }

  buildLocations() {
    this.modalTarget.classList.remove("active");
    for (let i = 0; i < this._markers.length; i++) {
      this._markers[i].setMap(null);
    }
    this._markers.splice(0, this._markers.length); // Clear previous markers from memory
    this.locationsValue.forEach((location) => {
      const locationLevel = location.accredited_application_level != null ? location.accredited_application_level : ''
      const icon = locationLevel != '' ? location.accredited_application_level : 'in_progress';
      const marker = new this.google.maps.Marker({
        position: {
          lat: parseFloat(location.latitude) + Math.random() / 25000,
          lng: parseFloat(location.longitude) + Math.random() / 25000,
        },
        map: this._map,
        locationID: location.id,
        popupHtml: location.map_popup_html,
        icon: {
          url: `data:image/svg+xml;charset=UTF-8;base64,${btoa(
            MapPins[icon]
          )}`,
        },
      });
      marker.addListener("click", () => {
        this.modalContentTarget.innerHTML = marker["popupHtml"];
        this.modalTarget.classList.add("active");
      });
      this._markers.push(marker);
    });

    this.markerClusterer(this._markers);
  }
}
