document.addEventListener('turbolinks:load', function() {
  const filter_inputs = document.querySelectorAll('.filter-input')
  filter_inputs.forEach(function(input) {
    const select = input.querySelector('select')
    checkIfSelected(select)
    if (select) {
      select.addEventListener('change', function(e) {
        checkIfSelected(e.target)
      })
    }
  })
})

function checkIfSelected(select) {
  if (select && select.selectedIndex) {
    select.parentElement.classList.add('filter-input--selected')
  } else {
    select.parentElement.classList.remove('filter-input--selected')
  }
}
