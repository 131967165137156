import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.element.querySelectorAll("input[type=radio]").forEach((radioInput) => {
      radioInput.checked = false;
    });
  }

  count(event) {
    event.preventDefault();
    this.stimulate("LevelQuiz#count");
  }

  clear(event) {
    event.preventDefault();
    this.stimulate("LevelQuiz#clear");
  }

  clearSuccess(element, _reflex, _noop, _reflexId) {
    window.scroll({
      top: element.offsetTop,
      behavior: "smooth",
    });
  }
}
