import ApplicationController from "./application_controller";
import { getMetaValue, findElement, removeElement, insertAfter } from "./utils/helpers.js"

export default class extends ApplicationController {
  static targets = ['title'];


  submit(event) {
    event.preventDefault();
    const form_data = {...serialize(new FormData(this.element)), ...{ evidence_content_attributes: { title: this.titleTarget.value } } }
    const options = {
      method: 'PATCH',
      body: JSON.stringify(form_data),
      headers: {
        'X-CSRF-Token': getMetaValue("csrf-token"),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    fetch(this.element.action, options)
      .then(response => {
        if (response.ok) {
          this.stimulate('PhotoEvidence#update')
        } else {
          this.emitDropzoneError(response.statusText)
        }
      })
  }

}
function serialize (data) {
	let obj = {};
	for (let [key, value] of data) {
		if (obj[key] !== undefined) {
      debugger
			if (!Array.isArray(obj[key])) {
				obj[key] = [obj[key]];
			}
			obj[key].push(value);
		} else {
			obj[key] = value;
		}
	}
	return obj;
}